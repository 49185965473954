import axios from "axios";
import { getApiBaseUrl } from "@src/utils/urlHelpers";
import { apiPaths } from "@src/utils/constants";
import authService, { useAuthentication } from "./services/auth.service";
import i18n from "./i18n";
import useTranslatedNavigate from "./services/useTranslateNavigate";

export const translateCell = (cell) => {
  return i18n.t(cell);
};

const authorizationErrorStatusCodes = [403, 401];

const AxiosSetup = ({ navigate }) => {
  const { logout } = useAuthentication();
  const { pathT } = useTranslatedNavigate();

  // This flag enables the Http Request to carry all Cookies present on browser for this app.
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = getApiBaseUrl();

  axios.interceptors.request.use(async (config) => {
    config.headers["Accept-Language"] = i18n.language;
    return config;
  });

  // If we received a 401 or a 403 response, try to obtain a new access token using the
  // refresh token
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const isRefreshRequest = error.config?.url.startsWith(
        apiPaths.refreshToken,
      );
      const isLoginRequest = error.config?.url.startsWith(apiPaths.login);
      const isTwoFactorRequest = error.config?.url.startsWith(
        apiPaths.twoFactorAuthentication,
      );
      const isLogoutRequest = error.config?.url === apiPaths.logout;
      const isAuthorizationError = authorizationErrorStatusCodes.includes(
        error?.response?.status,
      );
      // eslint-disable-next-line no-underscore-dangle
      const isRetry = !!originalRequest?._retry;

      if (isAuthorizationError) {
        if (isLoginRequest) {
          return Promise.reject(error);
        }

        if (!isLogoutRequest && isRetry) {
          logout();
          navigate(pathT("route.login"));
          // this saves a second call to logout
          // return Promise.reject(error);
        }
        if (isTwoFactorRequest) {
          return Promise.reject(error);
        }
        if (!isLogoutRequest && !isRefreshRequest && !isRetry) {
          // Next request is a retry, set the variable so we now
          // eslint-disable-next-line no-underscore-dangle
          originalRequest._retry = true;

          // This user should be gotten from sessionStorage. We need the email to refresh the access token,
          // and redux won't be loaded on first page load.
          const user = authService.getUserFromStorage();
          if (user?.email) {
            try {
              await authService.refreshAccessToken(user.email);
              return axios(originalRequest);
            } catch {
              logout();
              navigate(pathT("route.login"));
            }
          }
        } else {
          logout();
          navigate(pathT("route.login"));
        }
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      let message =
        error.request?.responseText || error.message || error.toString();
      if (error.response) {
        switch (error.response.status) {
          case 500: // INTERNAL_SERVER_ERROR
            message = translateCell("ErrorStrings.InternalServerError");
            break;
          case 401: // UNAUTHORIZED
            message = translateCell("ErrorStrings.Unauthorized");
            break;
          case 403: // FORBIDDEN
            message = translateCell("ErrorStrings.Forbidden");
            break;
          case 414: // REQUEST_TOO_LONG:
            message = translateCell("ErrorStrings.RequestToLong");
            break;
          case 400: // BAD_REQUEST:
            // message = translateCell('ErrorStrings.BadRequest');
            break;
          case 409: // CONFLICT:
            message = translateCell("ErrorStrings.Conflict");
            break;
          default:
            break;
        }
      }
      error.message = message;
      return Promise.reject(error);
    },
  );
  return null;
};

export default AxiosSetup;
