import { env } from "@src/env";

export const pendoService = {
  loadPendoScript: (apiKey: string): Promise<void> => {
    if (window.pendo) return Promise.resolve();

    const script = document.createElement("script");
    script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
    script.async = true;

    return new Promise((resolve, reject) => {
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Failed to load Pendo script."));
      document.head.appendChild(script);
    });
  },

  initializePendo: async (
    user: { id: string; email?: string; employerId?: string },
    apiKey: string,
  ) => {
    if (env.REACT_APP_HOSTING_ENV === "production") return;

    try {
      await pendoService.loadPendoScript(apiKey);

      if (!window.pendo) {
        console.error("Pendo is not available on the window object.");
        return;
      }

      window.pendo.initialize({
        visitor: {
          id: user.id,
          email: user.email,
        },
        account: {
          id: user.employerId,
        },
      });
    } catch (error) {
      console.error("Pendo initialization failed:", error);
    }
  },
};
